<template>
  <div class="scoringOperation">
    <el-select v-model="queryStatus"
      class="select-box"
      placeholder="请选择状态">
      <el-option v-for="status in statusList"
        :key="status.value"
        :label="status.name"
        :value="status.value"></el-option>
    </el-select>
    <!-- 还没结接口 -->
    <el-button type="primary">查 询</el-button>
    <el-table :data="evaluateList"
      class="table-box">
      <el-table-column :prop="questionnaireType===0?'mname':'assessed_name'"
        align="center"
        label="被考核教师"></el-table-column>
      <el-table-column prop="cname"
        align="center"
        label="班级"></el-table-column>
      <el-table-column prop="course"
        align="center"
        label="课表学科"></el-table-column>
      <el-table-column prop="bookname"
        align="center"
        label="科组学科"></el-table-column>
      <el-table-column prop="type"
        align="center"
        label="类型"></el-table-column>
      <el-table-column prop="opertime"
        align="center"
        label="时间"></el-table-column>
      <el-table-column :prop="questionnaireType===0?'statusstr':'statusStr'"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{questionnaireType===0?scope.row.statusstr:scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="questionnaireType===1"
            :type="typeColor(scope.row.status)"
            :disabled="questionnaireStatus===2"
            @click="scoreClick(scope.row)">
            <span v-if="scope.row.status===0">评 分</span><span v-else>评分修改</span>
          </el-button>
          <el-button v-if=" scope.row.type!==2 &&questionnaireType===0 &&scope.row.managerid === $store.state.userInfo.userid"
            :type="typeColor(scope.row.status)"
            :disabled="questionnaireStatus===2"
            @click="scoreClick(scope.row,0)">
            <span v-if="scope.row.status===0">自 评</span><span v-else>自评修改</span>
            <!-- <span v-if="scope.row.managerid===this.$store.state.userInfo.userid">自 评</span><span v-else>自评修改</span> -->
          </el-button>
          <el-button v-if="scope.row.type!==1 && questionnaireType===0 "
            :type="typeColor(scope.row.status)"
            :disabled="questionnaireStatus===2"
            @click="scoreClick(scope.row,1)">
            <span v-if="scope.row.status!==2">评 价</span>
            <span v-if="scope.row.status===2">评价修改</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="inestimableVisible"
      title="提示"
      width="600px">
      <span>需要自评完成才能进行评价</span>
      <div slot="footer">
        <el-button type="primary"
          @click="inestimableVisible=false">确 定</el-button>
        <el-button @click="inestimableVisible=false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "scoringOperation",
  data() {
    return {
      evaluateList: [],
      queryStatus: "",
      questionnaireId: Number(this.$route.query.id),
      questionnaireStatus: Number(this.$route.query.status),
      questionnaireType: Number(this.$route.query.type), //问卷类型
      statusList: [
        { name: "未评价", value: 0 },
        { name: "已自评", value: 1 },
        { name: "已评价", value: 2 },
      ],
      inestimableVisible: false,
    };
  },
  created() {
    this.loadEvaluateList();
  },
  methods: {
    //查询要评价的教师
    loadEvaluateList() {
      const that = this;
      let data = { ndid: this.questionnaireId };
      let apiUrl = "/base/PQueryTeaList.do"; //教师查询问卷该评价的教师列表
      if (this.questionnaireType === 0) {
        apiUrl = "/base/queryTeaNdEvaluation.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          console.log('res',res)
          res.data.forEach(val => {
            if(val.type ===0){
              val.type = '科组'
            }else{
              val.type = '课表'
            }
          });
          that.evaluateList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    scoreClick(row, type = -1) {
      if (type === 1 && row.status === 0) {
        this.inestimableVisible = true;
        return;
      }
      this.$router.push({
        name: "questionnairePage",
        query: {
          questionnaireType: this.questionnaireType,
          questionnaireStatus: this.questionnaireStatus,
		  opertime: row.opertime || '',
		  mname: row.mname,
          questionnaireId: row.id, //问卷id
          ndid: row.ndid, //年度id
          managerId:
            this.questionnaireType === 1 ? row.assessed_mid : row.managerid, //被评价人id
          status: row.status,
          type, //自评或他评
          qt_id: this.questionnaireType === 1 ? row.id : "",

        },
      });
    },
    typeColor(status) {
      if (status === 2) {
        return "success";
      }
      if (status === 1) {
        return "primary";
      } else {
        return "";
      }
    },
    tagColor(status) {
      switch (status) {
        case 0:
          return "success";
        case 1:
          return "warning";
        case 2:
          return "info";
        case 3:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-box {
  padding: 0 15px;
}
.table-box {
  margin-top: 15px;
}
</style>
